// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-government-js": () => import("./../../../src/pages/government.js" /* webpackChunkName: "component---src-pages-government-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-data-js": () => import("./../../../src/pages/live-data.js" /* webpackChunkName: "component---src-pages-live-data-js" */),
  "component---src-pages-live-statistics-range-js": () => import("./../../../src/pages/live-statistics-range.js" /* webpackChunkName: "component---src-pages-live-statistics-range-js" */),
  "component---src-pages-menoume-spiti-js": () => import("./../../../src/pages/menoume-spiti.js" /* webpackChunkName: "component---src-pages-menoume-spiti-js" */),
  "component---src-pages-mythbusters-js": () => import("./../../../src/pages/mythbusters.js" /* webpackChunkName: "component---src-pages-mythbusters-js" */),
  "component---src-pages-scientific-js": () => import("./../../../src/pages/scientific.js" /* webpackChunkName: "component---src-pages-scientific-js" */),
  "component---src-pages-statistics-js": () => import("./../../../src/pages/statistics.js" /* webpackChunkName: "component---src-pages-statistics-js" */)
}

