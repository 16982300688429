export const ADD_FEED_RSS_ITEMS = 'ADD_FEED_RSS_ITEMS';
export const ADD_FEED_RSS_ITEM = 'ADD_FEED_RSS_ITEM';
export const SET_FEED_ERROR = 'SET_FEED_ERROR';
export const START_FEED_LOADING = 'START_FEED_LOADING';
export const STOP_FEED_LOADING = 'STOP_FEED_LOADING';
export const ADD_GOVERNMENT_RSS_ITEMS = 'ADD_GOVERNMENT_RSS_ITEMS';
export const ADD_GOVERNMENT_RSS_ITEM = 'ADD_GOVERNMENT_RSS_ITEM';
export const SET_GOVERNMENT_ERROR = 'SET_GOVERNMENT_ERROR';
export const START_GOVERNMENT_LOADING = 'START_GOVERNMENT_LOADING';
export const STOP_GOVERNMENT_LOADING = 'STOP_GOVERNMENT_LOADING';
export const SET_GREEK_COVID_DATA = 'SET_GREEK_COVID_DATA';
export const SET_GREEK_COVID_DATA_ERROR = 'SET_GREEK_COVID_DATA_ERROR';
export const START_GREEK_COVID_DATA_LOADING = 'START_GREEK_COVID_DATA_LOADING';
export const STOP_GREEK_COVID_DATA_LOADING = 'STOP_GREEK_COVID_DATA_LOADING';
export const SET_COVID_DATA_RANGE = 'SET_COVID_DATA_RANGE';
export const SET_COVID_DATA_RANGE_ERROR = 'SET_COVID_DATA_RANGE_ERROR';
export const START_COVID_DATA_RANGE_LOADING = 'START_COVID_DATA_RANGE_LOADING';
export const STOP_COVID_DATA_RANGE_LOADING = 'STOP_COVID_DATA_RANGE_LOADING';
